<template>
  <b-form-input
    v-model.trim="search"
    v-bind="{
      value,
      type,
      placeholder,
      debounce
    }"
    @update="onUpdate"
  >

  </b-form-input>
</template>

<script>
import { timeout } from '@/config';

export default {
  name: 'CommonSearch',
  props: {
    value: {
      type: String,
      default() {
        return ''
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Поиск'
      },
    },
    type: {
      type: String,
      default() {
        return 'search'
      },
    },
    debounce: {
      type: Number,
      default() {
        return timeout
      },
    },
  },
  data() {
    return {
      search: this.value,
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update', {
        search: this.search,
      })
    },
  },
  watch: {
    value(query) {
      this.search = query
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
